import React, { Component } from "react";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LandingPage from "./components/Landing/LandingPage"
import Blog from "./components/Landing/blog"
// import "bootstrap/dist/css/bootstrap.min.css";
// import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4" ;
import AgentLandingPage from "./components/ai-agent/agent";
import Listing from "./components/marketplace/listing";
import Item from "./components/marketplace/item";
import Privacy from "./components/privacy"; 
import Terms from "./components/terms"

import RefineLandingPage from "./components/refineio/refine-landing" 

class App extends Component {
  state = {};


  componentDidMount() {
    
    if (process.env.REACT_APP_ENVIRON === "production" ) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID) ; 
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      // logger.init()
    }
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
          <Switch>
          {
            /*
            <Route from="/blog" component={ Blog } />
            <Route from="/fine-tune/:item" component={ Item } />
            <Route from="/fine-tunes" component={ Listing } />
            <Route from="/ai-agent" component={ AgentLandingPage } />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            */
          }

            <Route path="/" component={ RefineLandingPage } />
          </Switch>
      </React.Fragment>
    );
  }
}

export default App;