import React, { useState } from 'react';
import './refine-landing.css'; // Make sure the CSS file is named AgentLandingPage.css
import Header from '../Landing/header';
import Footer from '../Landing/footer';
import MediaQuery from "react-responsive";
import Modal from 'react-modal';

import ExternalIcon from "../assets/svgs/externalLink.png"



function AgentLandingPage() {

    const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }


  return (
    <div className='NewLandingPage agent-background' >
        <div className="bannerSection" >
                    {/* <Header /> */}
                    <div className="agent-header">
                        <h1 style={{ fontSize:'100px', fontWeight: '450' , marginTop: '80px' }}>Refine AI</h1>
                        <h5> Your AI Partner </h5>
                        <p className="mt-3" >
                            Advancements in Generative AI, particularly Large Language Models (LLMs), can improve data access and interpretation.
                            LLMs can be customized through techniques such as fine-tuning and in-context learning. Through this customization, models perform better on domain-specific tasks and can seamlessly integrate into business processes. 
                        </p>
                        <div className="agent-header-buttons mt-5">
                            <button class="button-primary"> <a href="https://calendly.com/zipscore/45min" target='_blank'> Let's Talk </a></button>
                            <button class="button-secondary" /* onClick={openModal}*/> <a href="https://apps.refineai.io" target='_blank'> Log In </a> </button>
                        </div>
                    </div>

                    <div className="agent-chart-placeholder" style={{ fontSize:'50px', fontWeight: '450' , marginTop: '45px'}}>
                            {/* <MediaQuery maxDeviceWidth={599}>
                                <iframe 
                                    src="https://player.vimeo.com/video/910116602?h=1144963e48&api=1&background=1&mute=0&quality=1080p" 
                                    style={{width : '90vw', height : '50vh' }}
                                    frameBorder="0" 
                                    allow="fullscreen; picture-in-picture" 
                                    title="audienceScoreDes"

                                /> 
                            </MediaQuery>

                            <MediaQuery minDeviceWidth={600} maxDeviceWidth={1223}>
                                <iframe 
                                    src="https://player.vimeo.com/video/910116602?h=1144963e48&api=1&background=1&mute=0&quality=1080p" 
                                    style={{width : '80vw', height : '60vh', borderRadius: '5px'}}
                                    frameBorder="0" 
                                    allow="fullscreen; picture-in-picture" 
                                    title="audienceScoreDes"
                                    className="iframeVideoVimeo"
                                /> 
                            </MediaQuery>

                            <MediaQuery minDeviceWidth={1224}>
                                <iframe 
                                    src="https://player.vimeo.com/video/910116602?h=1144963e48&api=1&background=1&mute=0&quality=1080p" 
                                    style={{width : '74.5vw', height : '74.2vh', borderRadius: '5px'}}
                                    frameBorder="0" 
                                    allow="fullscreen; picture-in-picture" 
                                    title="audienceScoreDes"
                                    className="iframeVideoVimeo"
                                /> 
                            </MediaQuery> */}
                    </div>              
               
                </div>

                <div className="use-cases-section">
                    <h2 className="mb-5">Use Cases</h2>

                    <div className="use-cases-container">
                        <div className="use-case">
                            <h4> Zipscore  </h4>
                            <p className="mt-5">
                            Zipscore is an AI copilot for efficient market research and location planning, offering insights from 75,000 locations. Its key features include collaborative planning, demographic filtering, and geo-fencing for strategic targeting decisions
                            </p>
                            <p className='p-1'>

                                <a href="https://www.zipscore.ai" target='_blank' className="btn btn-primary" style={{ marginRight: '10px' }}> Video </a>
                                <a href="https://www.zipscore.ai" target='_blank' className="ml-2"> Read More  <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                            
                            </p>
                            {/* <a style={{color: 'blue'}} href='https://docs.texti.ai/ai-agent/real-time-feedback-to-action-ai-agent' target="_blank"> Read More </a> */}
                        </div>
                        <div className="use-case">
                            <h4> DrugDrone  </h4>
                            <p className="mt-5">
                            An AI Co-Pilot was developed for Pfizer's 328 drug products. This AI tool not only provides answers to queries about dosage guidelines but also aids in the creation of instruction manuals for healthcare professionals.
                            </p>
                            <p className='p-1'>

                                <a href="https://vimeo.com/916727423/854bfa8b6c" target='_blank' className="btn btn-primary" style={{ marginRight: '10px' }}> Video </a>
                                <a href="https://apps.refineai.io" target='_blank' className="ml-2"> Read More  <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                            
                            </p>
                            {/* <a style={{color: 'blue'}} href='https://docs.texti.ai/ai-agent/real-time-feedback-to-action-ai-agent' target="_blank"> Read More </a> */}
                        </div>
                        <div className="use-case">
                            <h4> Algorithmic Audience Scoring  </h4>
                            <p className="mt-4">
                            For a major global retailer, Refine AI developed a ML audience scoring algorithm to rank ZIP Codes in Germany. This tool enhances their marketing campaigns by targeting ZIP Codes likely to boost sales, tailored to specific product categories.
                            </p>
                            <p className='p-1'>

                                <a href="https://vimeo.com/558486464/" target='_blank' className="btn btn-primary" style={{ marginRight: '10px' }}> Video </a>
                                <a href="https://apps.refineai.io" target='_blank' className="ml-2"> Read More  <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                            
                            </p>
                            {/* <a style={{color: 'blue'}} href='https://docs.texti.ai/ai-agent/real-time-feedback-to-action-ai-agent' target="_blank"> Read More </a> */}
                        </div>
                        
                    </div>
                    

                </div>
            <Footer />
    </div>
  );
}

export default AgentLandingPage;